@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,615;0,800;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,200;0,300;0,400;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400&display=swap);
/* @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap'); */
/* @import url('http://fonts.googleapis.com/css2?family=Montserrat,wght@0,200;0,300;0,400;1,200&display=swap'); */
body { 
	margin: 0;
	padding: 0;
    /* font-family: 'Jost', serif; */
    font-family: 'Montserrat', sans-serif;
	font-size: 18px;
	position: relative;
	text-align: center;
	color: #555;
	background-color: #fefefe;
}
.wrapper {
    text-align: left;
}


a {
    color: gray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.books-list {
	border-bottom: 1px solid #eee;
}

button {
    cursor: pointer;
}

.wrapper {
    position: relative;
}

input {
    font-size: 1em;
    display: block;
    padding: .5em;
}

.cart-image{
    color: rgb(68, 68, 68);
    margin-right: .2em;
}

.cart-image:hover{
    color: black;
}

.fa-minus-circle {
    cursor: pointer;
}



.content {
	max-width: 1000px;
	margin: auto;
	padding-bottom: 5em;
    padding-top: 60px;
}

.content h1 {
	margin-top: 1em;
    margin-bottom: 1em;
    font-weight: 300;
    font-size: 3em;
}

.content h2 {
    font-weight: normal;
}

.content h3 {
    font-weight: normal;
}

.content p {
	font-weight: 300;
}

.content img {
	max-width: 100%;
}

.content-half {
	position: relative;
	display: inline-block;
	width: 50%;
	box-sizing: border-box;
	vertical-align: top;
}

.left-content {
    position: relative;
    display: inline-block;
    width: 20%;
}
.right-content {
    position: relative;
    vertical-align: top;
    display: inline-block;
    width: 80%;
}

.right-content a{
    text-decoration: underline;
}


/*product */

.product-container {
    position: relative;
    display: inline-block;
    width: 25%;
    height: 380px;
    margin-bottom: 2em;
    vertical-align: top;
    font-size: .8em;
    text-align: center;
}

.product-container img{
    /* width: 130px; */
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
} 

.product-image-container {
    position: relative;
    margin-top: 1em;
    width: 70%;
    margin-left: 15%;
    height: 180px;
}

.product-container .add-to-cart-button {
    position: absolute;
    bottom: .5em;
    width: 80%;
    left: 10%;
}

.product-container h3{
    font-size: 1.4em;
    padding-left: .5em;
    padding-right: .5em;
    position: relative;
    min-height: 3em;
    margin: 0;
    margin-top: .5em;
    max-height: 3em;
    overflow: hidden;
}

.product-container:hover {
box-shadow: -1px 3px 18px 0px rgba(0,0,0,0.35);
}

.product-container-info{
    color: #555;
    font-size: 1.4em;
}

.product-container-info h4, .product-container-info p{
    margin-top: 0;
    padding: 0;
    margin-bottom: 0;
}

.product-container-info h4 {
    font-size: .8em;
    display: inline-block;
}

.product-mid {
    display: inline-block;
    position: relative;
    vertical-align: top;
    padding-left: 1em;
    width: 34%;
    
}

.product-mid .product-right-title {
    font-size: 2.5em;
    margin-bottom: 0;
    margin-top: 0;
}

.product-authors{
    position: relative;
    width: 100%;

}

.product-container-info .product-authors{
    line-height: 1em;
    height: 2em;
    overflow: hidden;
}

.authors-span{
    display: inline-block;
    margin-right: 1em;
}

.authors-span + .authors-span::before {
    display: inline-block;
    white-space: pre;
    /* content: ", "; */
  }

.product-mid .star-ratings {
    margin-top: 1em;
}

.product-authors h3 {
    display: inline;
    /* margin-right: .4em; */
}

.add-to-cart-button {
    background: #6a4275;
    color: white;
    border-radius: 5px;
    border: none;
    font-size: 1em;
    margin-top: .5em;
    padding: .3em .5em .4em .5em;
    cursor: pointer;

    

}

.add-to-cart-button:hover {
    background: #946aa0;

}

.wishlist-button {
    background: hsl(327, 63%, 54%);
}

.wishlist-button:hover {
    background: #e48dbf;
}

.modal {
    position: fixed;
    top: 2em;
    right: 2em;
    width: 20%;
    height: auto;
    text-align: center;
    background: rgb(252, 146, 26);
    border-radius: .5em;
    z-index: 10;
box-shadow: -7px 12px 19px -5px rgba(0,0,0,0.27);
}

.modal p{
    display: inline-block;
    width: 96%;
    padding-left: 2%;
    padding-top: 1em;
    padding-bottom: 1em;
    
}

.modal button{
    position: absolute;
    display: inline-block;
    right: 0;
    top: 0;
    background: rgb(180, 180, 180);
    color: white;
    font-weight: bold;
    z-index: 4;
    border: none;
    border-radius: .5em;
}

@media screen and (min-width: 500px) {
  /* Center the Modal! */
  .modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 30em;
    max-height: calc(100% - 1em);
  }
}

._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-body {
  padding-top: 0.25em;
}
._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

.sticker-container{
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    width: 35%;
    margin-top: 1em;
}

.sticker-container img{
    max-width: 100%;
    height: auto;
}

.sticker-container p{
    position: absolute;
    font-size: 1em;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
}

.sticker-container-products{
    margin-top: 1.5em;
    
}

.sticker-container-products p{
    font-size: 1.2em;
    top: 13%;
    left: 50%;
}

.sticker-container-product{
    right: -1.3em;
    width: 32%;
    margin-top: 2em;
}

.sticker-container-product img{
    border: none !important;
    box-shadow: unset !important;
}

.sticker-container-product p{
    font-size: 1.2em;
    top: 0;
}

.product-slider {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    overflow-x: scroll;
}

.product-slider img{
    width: 70%;
    margin: 15%;
    margin-bottom: 0;
}

.product-slider ul{
    white-space: nowrap;
    
}
.product-slider ul li{
    width: 20%;
    display: inline-block;
    /* white-space: normal; */
    
}
.product-slider ul li h3{
    height: 3em;
    
}

.product-slider-container {
    width: auto;
}

.notify-me input {
    display: inline;
}


.product-view {
    width: 100%;
    display: inline-block;
    position: relative;
    white-space: normal;
    text-align: center;
}

.product-view h3{
    margin-bottom: 0;
    min-height: 2.5em;
    line-height: 1.2em;
}

.product-view p{
    margin-top: 0;
    font-size: 1.2em;
}

.product-view img{
    margin: auto;
    max-width: 75%;
    height: auto;
    max-height: 16em;
    
}

.product-view-author {
    display: inline-block;
    position: relative;
    margin: .1em .3em;
    font-size: .9em;
}



.product-slider-container .product-view {
    display: inline;
}

.product-left {
    display: inline-block;
    position: relative;
    width: 25%;
    z-index: 2;

}

.product-left img{
    cursor: zoom-in;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 7px 5px #fff;
}

.product-right {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-left: 2em;
    width: 30%;
    background: #eee;
    text-align: center;
    padding-bottom: 2em;
    
}

.product-right hr{
    margin-left: 2em;
    margin-right: 2em;
    color: gray;
}

.product-right-toggle {
    cursor: pointer;
}

.product-right .product-right-title {
    font-size: 2.5em;
    margin-bottom: 0;
    margin-top: 0;
}

.product-right h3 {
    margin-top: 0;
}

.product-container-info .old-price { 
    margin-bottom: 0;
    font-size: .8em;
}

.product-container-info h2 {
    margin-top: 0;
}
.product-container-info .authors-span {
    margin-right: .5em;
}

.home {
    text-align: center;
}

.home h2 {
    margin-top: 2em;
    margin-bottom: 0;
}
.home h2 hr{
    width: 5%;
    border: 1px solid gray;
}

.home-image {
    width: 100%;
}

.categories-container-wrapper {
    display: block;
    position: relative;
}
.categories-container {
    display: block;
    height: 1.6em;
    overflow-y: hidden;
}

.categories-container a {
    text-decoration: none;
}

.categories-container:hover {
    height: auto;
}

.categories-container ul{
    list-style-type: none;
    margin: .2em;
    padding: 0 .5em;
    text-align: center;
    font-weight: 300;
}
.categories-container ul li{
    display: inline;
    cursor: pointer;
    margin-left: 1em;
    color: #999;
}
.categories-container ul li:hover{
    text-decoration: underline;
    color: black
}

.arrow-open:before {
    content: '▼ ';
}
.arrow-closed:before {
    content: '► ';
}


.categories a{
    margin: 0;
    padding: 0;
    display: block;
    color: gray;
    cursor: pointer;
}

.subcategories-container {
    display: none;
}


.register-button {
    border: 1px solid gray;
    border-radius: 5px;
    font-size: 1em;
    padding: .2em .5em;
    background: white;
    cursor: pointer;
}

.register-button:hover {
    background: gray;
    color: white;
}

.registration-container{
    margin-top: .5em;
    position: relative;
    display: inline-block;
    font-size: .8em;
}
.registration-container span{
    margin-right: .5em;
    color: gray;
    font-style: italic;
}

iframe {
    border: none;
    width: 100%;
    height: 20em;
}

.pdf-iframe {
    width: 60%;
    height: 1000px;
    margin-left: 20%;
}

.news-slider-container {
    position: relative;
    text-align: center;
    display: inline-block;
    width: 300px;
    vertical-align: top;
    margin: 0 40px;
    
}

.news-slider-container p{
    position: relative;
    /* text-align: center; */
    display: inline-block;
    text-align: left;
    background: white;
    margin: 2%;
    font-size: .9em;
    padding: 2%;
    line-height: 1.2em;
    overflow: hidden;
    height: 3.5em;
    width: 90%;
    
}

.news-slider-container p a{
    font-style: italic;
    color: #694374;
    font-weight: bold;

}
.news-slider-container p a:hover{
    text-decoration: underline;
}

.news-slider-container div{
    position: relative;
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-left: 5px;
    margin-top: 5%;
    background: #eee;
}

.news-slider-container img{
    position: relative;
    width: 290px;
    height: 290px;
    margin-top: 10px;
    margin-left: 10px;
    border: 1px solid gray;
}

.news-slider-container .image{
    position: relative;
    /* width: 290px; */
    width: 280px;
    display: inline-block;
    height: 280px;
    margin-top: 10px;
    margin-left: 0px;
    border: 1px solid gray;
  background-size: cover;
  background-position: center center;
}


.top-product{
    margin-right: 2px;
    padding-bottom: .5em;
}

.top-product:nth-child(even) {
    background: #eee;
} 

.top-product img {
    position: relative;
    display: inline-block;
    width: 20%;
    vertical-align: top;
    margin-top: .5em;
    margin-left: .5em;
}

.top-product div {
    position: relative;
    display: inline-block;
    width: 70%;
    font-size: .8em;
    padding-left: 1em;
}

.top-product p {
    color: gray;
    margin: .5em;
}

.top-product h4 {
    color: gray;
    margin: .5em;
}

.top-product h3 {
    margin: .5em;
}

.slick-slider {
    margin-bottom: 2em;
}

.home-checkbox {
    width: auto;
    margin-right: .5em;
    display: inline-block;
}

.new-products-container{
    position: relative;
}

.top-products-container {
    position: relative;
}

.top-products-container .sticker-container {
    display: none;
}


.top-position {
    position: absolute;
    /* top: 1em;
    left: 20%; */
    top: .2em;
    left: 5%;
    z-index: 2;
    display: inline-block;
    line-height: 2.5em;
    width: 2.5em;
    height: 2.5em;
    background: #6a4275;
    color: white;
    border-radius: 50%;
    border: 1px solid white;
    font-weight: bold;
    text-align: center;
}


.control input[type="radio"] {
    display: inline;
}

.social-header{
    position: absolute;
    right: 1em;
    bottom: 1.6em;
}
.social-header svg{
    height: 20px;
}

.social-header a{
    border: 1px solid #d4d4d4;
    margin: .2em;
    padding: .5em;
    padding-bottom: 0em;
    border-radius: 5px;
}


.news-sidebar{
    background: white;
    font-size: .8em;
    width: 90%;
    border-bottom: 1px solid rgb(228, 226, 226);
}

.news-sidebar p{
    color: gray;
}

.news-single-container img{
    max-width: 100%;
}

.cart-products-header {
    font-weight: bold;
}

.cart-products-header div {
    display: inline-block;
    position: relative;
    text-align: center;
}

.cart-products-header div:first-child {
    text-align: left;
}
.cart-products-header div:last-child {
    text-align: right;
}

.cart-products-header-image {
    width: 10%;
}
.cart-products-header-title {
    width: 20%;
}
.cart-products-header-price {
    width: 20%;
}
.cart-products-header-quantity {
    width: 20%;
}
.cart-products-header-total {
    width: 20%;
}
.cart-products-header-remove {
    width: 10%;
}

.cart-products-body {
    /* font-weight: 400; */
    border-bottom: 1px solid rgb(204, 204, 204);
    padding-top: .5em;
}

.cart-products-body div {
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: middle;
    
}

.cart-products-body div:first-child {
    text-align: left;
}
.cart-products-body div:last-child {
    text-align: right;
}

.cart-products-body-image {
    width: 10%;
}
.cart-products-body-image img{
    height: 50px;
}
.cart-products-body-title {
    width: 20%;
}
.cart-products-body-price {
    width: 20%;
}
.cart-products-body-quantity {
    width: 20%;
}
.cart-products-body-quantity span{
    display: inline-block;
    width: 2em;
}
.cart-products-body-total {
    width: 20%;
}
.cart-products-body-remove {
    width: 10%;
}


.cart-product {
    position: relative;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid gray;
    
}

.cart-product button{
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid rgb(190, 190, 190);
    padding: .5em 1em;   
    background: white;
}

.cart-product div{
    padding-top: 1em;
    padding-bottom: 1em;
    vertical-align: middle;
    padding-left: 1em;
}

.cart-product .title{
    position: relative;
    display: inline-block;
    font-weight: bold;
    width: 30%;
    
}

.cart-product .image{
    position: relative;
    display: inline-block;
    font-weight: bold;
    width: 10%;
}

.cart-product .image img{
    height: 60px;
}

.cart-product .quantity{
    position: relative;
    display: inline-block;
    width: 20%;
    text-align: center;
}

.cart-product .quantity span{
    font-size: 1.2em;
    margin-left: .5em;
    margin-right: .5em;
    position: relative;
    display: inline-block;
    width: 3em;
    text-align: center;
}

.cart-product .price{
    position: relative;
    display: inline-block;
    width: 20%;
    text-align: right;
}

.cart-product .trash{
    position: relative;
    display: inline-block;
    width: 10%;
    text-align: right;
}

.cart-product-head{
    background: #694374;
    color: white;
    margin-top: 2em;
}

.cart-inputs {
    display: inline-block;
    position: relative;
    width: 30%;
    vertical-align: top;
    margin-right: 3%;
    text-align: center;
}

.cart-inputs ul{
    text-align: left;
}

.cart-inputs input{
    display: inline-block;
    position: relative;
    width: 100%;
}

.cart-inputs h3{
    margin-bottom: .5em;
}

.cart-input-radio {
    position: relative;
    display: inline-block;
    margin-top: 1.4em;
}

.cart-inputs-billing {
    display: inline-block;
    position: relative;
    width: 50%;
    vertical-align: top;
}

.cart-inputs-billing div{
    display: inline-block;
    position: relative;
    width: 75%;
    vertical-align: top;
    margin-left: .5em;
}

.cart-inputs-billing div h4{
    margin-bottom: 0;
}

.town-checkbox{
    text-align:left; 
    position:relative;
    margin: 0.5em 0;
}

.cart-street-box{
    width: 72%;
    display: inline-block;
    margin-right: 6%;
}

.cart-streetNum-box{
    width: 22%;
    display: inline-block;
}

.cart-submit {
    background: rgba(105, 67, 116, 0.25);
    padding: 1em 4em;
    text-align: center;
    font-weight: normal;
    font-size: 1.8em;
    margin-top: 2em;
}

.cart-head-half {
    width: 48%;
    display: inline-block;
    position: relative;
    font-weight: bold;
}

.cart-submit strong{
    font-weight: bold;
}

.cart-submit a{
    text-decoration: underline;
    cursor: pointer;
}

.cart-submit button{
    background: #66C09E;
    font-weight: bold;
    font-size: 1.4em;
    margin-top: 1em;
    color: white;
    padding: .2em 1em;
    cursor: pointer;
}

.cart-total {
    width: 100%;
    text-align: right;
}


.cart-total h5{
    font-style: italic;
    font-weight: normal;
}

.error {
    /* position: absolute; */
    margin-top: 0;
    color: red;
    font-size: .8em;
    font-style: italic;
}


.star-ratings {
    display: block;
    margin-top: 0;
}

.form.sign-up {
    position: relative;
    width: 40%;
    margin: 5% 30%;
    padding: 2%;
}

.form.sign-up input[type="text"] {
    box-sizing: unset;
    padding: 0.5em;
}
.form.sign-up input[type="password"] {
    box-sizing: unset;
    padding: 0.5em;
    margin-bottom: 0;
}

.form.sign-up input {
    width: 95%;
}

.form.sign-up label {
    display: block;
    margin-top: .5em;
}

.sign-up .button {
    background-color: white;
    border: 1px solid gray;
    border-radius: 3px;
    color: black;
    text-decoration: none;
    text-shadow: -1px 1px 50px #2f6627;
}

.sign-up .button.action-button {
    display: inline-block;
    margin: 0 0 0 auto;
    padding: .2em .5em;
    font-size: 1em;
    width: auto;
    cursor: pointer;
    margin-top: 1em;
}

.form .login-button-container {
    text-align: right;
}
.form .register-button-container {
    text-align: right;
}

.form .forgot-password-container {
    text-align: center;
}

.form.sign-up h2 {
    text-align: center;
}


.registration div{
    position: relative;
    display: inline-block;
    width: 46%;
    vertical-align: top;
}

.registration div:last-child{
    margin-left: 6%;
}

.registration h2{
    color: white;
    background: #694374;
    padding: .5em;
    width: 100%;
}


.registration label{
    display: inline-block;
    position: relative;
    margin-top: 1em;
    margin-left: 2%;

} 

.registration input{
    width: 96%;
    margin-left: 2%;

} 

.registration button{
    border-radius: 5px;
    font-size: 1em;
    padding: .5em 1em;
    background: rgb(70, 139, 241);
    border: none;
    color: white;
    cursor: pointer;
    margin-top: 2em;
    margin-left: 2%;
}
.registration button:hover{
    background: rgb(128, 173, 241);
}

.registration a{
    cursor: pointer;
    /* color: rgb(70, 139, 241); */
    text-decoration: underline;
    font-style: italic;
}

.registration input[type="checkbox"] {
    display: inline;
    position: relative;
    vertical-align: bottom;
}

.comments {
    display: inline-block;
    width: 50%;
}

.comment {

    border: 1px solid rgb(202, 202, 202);
    border-radius: .5em;
    padding: .5em;
    color: gray;
    margin-top: .5em;
    font-size: .8em;
}

.comment h4{
    margin-top: 0;
    margin-bottom: 0;
}
.comment h5{
    margin-top: .5em;
    margin-bottom: 0;
    text-align: right;
    font-weight: normal;
}
.comment h5 span {
    font-style: italic;
}
.comment p {
    margin-top: 0;
    margin-bottom: 0;
    color: black;
}

/* .comments button{
    margin-bottom: 2em;
} */

.comments textarea {
    resize: none;
    width: 98.5%;
    height: 10em;
    font-size: 1em;
    margin-top: 2em;
}

.message-page {
    text-align: center;
    margin-bottom: 10em;
    margin-top: 5em;
}

.pages {
    text-align: center;
}

.old-price {
    text-decoration: line-through;
    color: gray;
    font-size: 1.2em;
}

.social-icons {
    margin-top: 1em;
    padding-top: .8em;
    padding-bottom: .6em;
    width: auto;
    display: block;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.social-icon {
    display: inline-block;
    margin-right: 1em;
    cursor: pointer;
}

.help-button {
    position: fixed;
    right: 0;
    width: 2%;
    top: 38%;
    z-index: 4;
    background: orange;
    color: white;
    padding: .5em;
    text-align: center;
    font-weight: 900;
    cursor: pointer;
    border-radius: .5em 0 0 .5em;
}

.help-hidden {
    position: fixed;
    right: -200px;
    width: 200px;
    top: 40%;
    z-index: 3;
    background: #ddd; /* Safari prior 6.1 */
    transition: right 1s;
    border-radius: .5em 0 0 .5em;
}

.help-visible {
    position: fixed;
    right: 0;
    width: 200px;
    top: 40%;
    z-index: 3;
    background: #ddd; /* Safari prior 6.1 */
    transition: right 1s;
    border-radius: .5em 0 0 .5em;
}

.help-content {
    padding: 5%;
    width: 90%;
}

.pager-container {
    text-align: center;
    padding-left: 0;
}

.pager-container li{
    display: inline;
    border: 1px solid #694374;
    background: #694374;
    margin: .2em;
    /* padding: 0 .5em; */
    padding: 0;
    cursor: pointer;
    color: white;
    border-radius: 3px;
    font-size: 1.2em;
}

.pager-container li a {
    text-decoration: none;
    padding: 0 .5em;
    
}

.pager-selected{
    background: #ffffff !important;
    color: #694374 !important;
    border: 1px solid #9c78a7;
}

.slick-next:before, .slick-prev:before {
    font-size: 26px;
}

.slick-prev:before,
.slick-next:before {
    /* font-family:initial!important; */
    color:rgb(163, 163, 163); 

}

.sold-out {
    background: none;
    border: 1px solid #694374;
    color: #694374;
    cursor: default;
}

.sold-out:hover {
    background: none;
    border: 1px solid #694374;
    color: #694374;
}

.uplatnica{
    width: 80%;
    display: block;
    margin-top: 1em;
}

em {
    font-weight: bold;
}

.order-note {
    width: 100%;
    height: 15em;
}

.product-main-image{
    min-height: 350px;
}


.secondary-image-cont{
    /* width: 80px; */
    height: 120px;
    display: inline-block;
    position: relative;
    cursor: zoom-in !important;
    vertical-align: top;
    margin-right: .5em;
    margin-top: 2em;
    /* border: 1px solid #ddd; */
}

.secondary-image-cont-mobile{
    display: none;
}


.secondary-image-cont:hover{
    border: 1px solid rgb(182, 182, 182);
    box-shadow: 0px 0px 5px 3px rgb(216, 216, 216);
    
}

.secondary-image{
    /* position: absolute; */
    max-width: 100%;
    max-height: 100%;
    /* margin: 5%; */
    /* width: 32px;
    display: inline-block;
    position: relative;
    cursor: pointer; */
}

.popup-content{
    height: 90%;
    border-radius: .5em;
    text-align: center;
    border: 1px solid rgb(192, 192, 192) !important;
    max-height: 1000px;
}

.popup-overlay {
    background: rgba(0,0,0,0.2) !important;
}

.popup-content button{
    font-size: 2em;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    font-weight: normal;
    border: 2px solid #ddd;
    border: none;
    color: gray;
    top: 46%;
}

.popup-content .popup-prev{
    position: absolute;
    left: 0;
    /* top: 50%; */
    padding-left: .3em;
} 

.popup-content .popup-next{
    position: absolute;
    right: 0;
    /* top: 50%; */
    padding-right: .3em;
}

.image-preview {
    left: 0;
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0,0,0,0.2);
    z-index: 5;
}

.popup-slider-cont{
    position: fixed;
    width: 60%;
    height: 80%;
    left: 15%;
    top: 5%;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    
    text-align: center;
    padding: 2% 5%;
}

.popup-slider-cont .slick-slider{
    height: 90%;
}

.popup-slider-cont .slick-slider .slick-list{
    height: 100%;
}
.popup-slider-cont .slick-slider .slick-list div{
    height: 100%;
}

.popup-slider-cont .slick-slider .slick-track{
    height: 100% !important;
}

.popup-slider-cont .slick-slider img{
    max-height: 100%;
    max-width: 100%;
    width: auto !important;
}


.product-video-cont {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.product-video-cont iframe{
    width: 640px;
    height: 360px;
}

.vd-slider {
    overflow-x: hidden;
    position: relative;
    width: 100%;
    text-align: left;
}

.vd-slider-container {
    display:inline-block;
    position: relative;
    width: 10000px;
    left: 0;
    top: 0;
    
}

.vd-slider-animation {
    transition: left 0s;
}

.vd-slider-element {
    display: inline-block;
    width: 200px;
    overflow-x: hidden;
    vertical-align: top;
}

.vd-slider .vd-slider-button {
    position: absolute;
    top: 45%;
    width: 25px;
    cursor: pointer;
}

.vd-slider span img{
    width: 100%;
}

.vd-slider .button-previous{
    left: 0;
}

.vd-slider .button-previous img{
    transform: scaleX(-1);
}

.vd-slider .button-next {
    right: 0;
}

.test-container{
    position: relative;
    width: 100%;
    margin: 3em 0;
}

.test-box {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}


.test-box object{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.test-box object a{
    color: blue;
    text-decoration: underline;
}

.registration .registration-message {
    display: inline-block;
    text-align: center;
    font-size: 1.2em;
    width: 100%;
    position: relative;
}
.registration-message img {
    width: 50%;
}

pre {
    font-family: inherit;
}


.success-table {
    border-collapse: collapse;
    width: 60%;
}

.success-table tr:first-child{
    font-weight: bold;
}
.success-table td{
    border: 1px solid rgb(107, 107, 107);
    padding: .5em;
    text-align: center;
}
.success-table td:first-child{
    text-align: left;
}
.success-table td:last-child{
    text-align: right;
}

/* Footer */
.footer {
	width: 100%;
	background: #3f3f3f;
	color: white;
	font-weight: lighter;
	text-align: center;
/*	padding-bottom: .5em;*/
	padding-top: .5em;
}

.footer .bank {
	background-color: white;
}

.footer .bank img {
	height: 30px;
	margin: .4em;
}

.footer .social {
	font-size: 0.6em;
	margin-bottom: 1em;
}

.footer hr{
	width: 80%;
} 

.footer-bottom {
    margin: 1em 0 1em 0;
}

.footer-bottom a {
    color: white;
}

.footer-bottom-left {
    position: relative;
    display: inline-block;
    width: 60%;
    text-align: center;
    padding-left: 1em;
}
.footer-bottom-left ul{ 
    font-weight: bold;
    padding: 0;
}
.footer-bottom-left li{ 
    margin-left: .5em;
    padding-left: .5em;
    border-left: 1px solid;
    display: inline;
    text-decoration: none;
}

.footer-bottom-left li:first-child{
    border: none;
    margin-left: 0;
    padding-left: 0;
}

.footer-bottom-left li a{ 
    color: white;
    cursor: pointer;
    text-decoration: none;
}
.footer-bottom-left li a:hover{ 
    color: gray;
}

.tou-footer, .pp-footer {
    display: inline-block;
}

.social a {
	font-size: 3em;
	margin: .2em;
	color: #ccc;
}

.social a:hover {
	color: gray;
}

/* Shop page */
.color-header {
	font-weight: lighter;
}

.color-titles {
    color: gray;
}

#title-center, #subtext-center {
    text-align: center;
}

.home-product {
	width: 27%;
    margin: 3%;
	display: inline-block;
	position: relative;
	vertical-align: top;
}

.home-product-image {
    position: relative;
	width: 270px;
    height: 360px;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: center; 
    background-size: cover;
}

.home-product h3 {
	margin: 0;
    font-size: 1.5em;
    color: #333;
    text-align: center;
    height: 3em;
}

.home-product p {
    height: 1.5em;
    overflow: none;
    line-height: 1em;
}
.home-product p strong {
    font-weight: bold;
    color: black;
}

.home-product-price {
	text-align: right;
	font-weight: normal;
	font-size: 1.4em;
	color: black;
    margin-bottom: 1em;
}

.home-product .home-product-image:hover {
    opacity: .7;
}

.shop-button {
	background-color: white;
    border: 1px solid gray;
    border-radius: 3px;
    display: block;
    position: relative;
    cursor: pointer;
    color: black;
    font-size: 1em;
    text-decoration: none;
    text-shadow: -1px 1px 50px #2f6627;
    width: 100%;
    text-align: center;
    padding-top: .2em;
    padding-bottom: .2em;
}
.shop-button:hover {
	background-color: gray;
	color: #ffffff;
}

.shop-button:active {
	position:relative;
	top:1px;
}

/* Terms of use */
.terms-of-use p {
	text-align: left;
}

/* Contact */
.data-left {
	position: relative;
	display: inline-block;
	width: 49%;
	text-align: left;
	vertical-align: top;
}

.data-right {
	position: relative;
	display: inline-block;
	width: 40%;
	vertical-align: top;
}

/* Cart page */
.table {
	width: 100%;
	vertical-align: center;
}

.table th {
	padding: 8px;
	border-bottom: 1px solid #d3d4d5;
}

.table td {
	padding: 8px;
	border-bottom: 1px solid #d3d4d5;	 
}

.table img{
	width: 2em;
}

.add-remove {
	background-color: #fefefe;
    border-radius: .4em;
    display: inline-block;
    cursor: pointer;
    font-size: 1em;
    padding: 6px 10px;
    width: 1.7em;
    border: none;
    padding: .25em .5em;
    margin: .5em;
    color: black;
    border: 1px solid black;
    border-radius: .3em;
}

.add-remove:hover {
	background-color:#bfbfbf;
	color: #ffffff;
}

.add-remove:active {
	position:relative;
	top:1px;
}

.form {
	margin-top: 80px;
    border-radius: 5px;
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid gray;
}

.form input{
    color: #333;
	font-family: 'Montserrat';
    margin-bottom: 0 !important;
}

input[type=text], input[type=password] {
	width: 100%;
	position: relative;
	display: inline-block;
	padding: .5em;
	font-size: 1em;
	margin: 0;
	margin-bottom: 1em;
	font-weight: 300;
	border: 1px solid #ccc;
    border-radius: .2em;
    box-shadow: 0 0 10px #eee;
    margin-top: .2em;
}

input[type=submit] {
	border: 2px solid #cc0000;
	border-radius: .5em;
	font-size: 1em;
	padding: .3em 1em;
	margin: 1em 0;
	cursor: pointer;

}

input[type=text], select, textarea {
	width: 100%;
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
}

input[type=submit] {
	background-color: gray;
	border-radius:6px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-size:17px;
	font-style:italic;
	padding:2px 21px;
	text-decoration:none;
	text-shadow:-1px 1px 50px #2f6627;
	border: none;
}

input[type=submit]:hover {
  background-color: #e2c3e2;
}

.shop-button-order {
	background-color: gray;
	border-radius: 12px;
	display: inline-block;
	cursor: pointer;
	color: #ffffff;
	font-size: 20px;
	font-style: bold;
	padding: 1em 5em;
	text-decoration: none;
    margin: .5em 0;
	/* text-shadow: -1px 1px 50px #2f6627; */
	border: none;
}
.shop-button-order:hover {
	background-color:#707070;
	color: #ffffff;
}

.shop-button-order:active {
	position:relative;
	top:1px;
}

/* Home page */
.top {
	position: relative;
    margin-top: 60px;
}
.top img{
	width: 100%;
}
.top .top-image-mobile {
	display: none;
}
.top-content {
	position: absolute;
	text-align: center;
	width: 100%;
	top: 30%;
	color: white;
	font-size: 1.5em;
}
.top-content p{
	border-bottom: 2px solid #cc0000;
	display: inline-block;
}

.top-content h1, .top-content p{
	margin: .2em;
}

.product-single-description {
    position: relative;
    display: inline-block;
    width: 45%;
    margin-left: 5%;
    vertical-align: top;
}

.content .product-single h2 {
    text-align: center;
    font-size: 2em;
}

.product-signgle-cart-container {
    text-align: right;
}

.product-signgle-cart {
    width: 40%;
    display: inline-block;
    margin-top: 2em;
    margin-bottom: 2em;
}

.product-single-images {
    display: inline-block;
    vertical-align: top;
    width: 50%;
}

.product-single-image {
    display: inline-block;
    width: 100%;
}

.product-single-gallery-image {
    display: inline-block;
    vertical-align: top;
    width: 25%;
}

.home-products a {
    text-decoration: none;
}
/* HOME SLIDER */
.carousel .slider-wrapper.axis-horizontal .slider .slide {
    border: none !important;
}

.carousel .slide {
    background: none;
}

.logo img {
    height: 1em;
}

.per-page-container {
    display: inline;
    margin-left: 1.5em;
    float: right;
}

.per-page-container label {
    margin-right: .5em;
}

.per-page-container select {
    width: 50%;
}

.help {
    font-size: .6em;
}

.menu-categories-list, .parent-subcategories {
    list-style: none;
    margin: 20px 0 0 20px;
}

.menu-categories-list li {
    position: relative;
    border: .5px solid lightblue;
}

.menu-categories-list li :is(input, svg, p, .menu-select) {
    display: inline-block;
}

.menu-categories-list li .move-arrows{
    margin: auto;
    position: absolute;
    top: 50%; 
    right: 45%;
    transform: translateX(-100%);
    transform: translateY(-50%);
    width: 20px;
    height: auto;
}

.menu-categories-list li .up-arrow{
    right: 45%;
    transform: translateY(-50%);
}

.menu-categories-list li .down-arrow{
    right: 42%;
    transform: translateY(-50%);
}

.menu-select {
    margin: auto;
    position: absolute;
    top: 50%; 
    right: 0%;
    transform: translateX(-100%);
    transform: translateY(-50%);
    width: 40%;
}

.search-container {
    margin: 10px 0;
}
@media screen and (max-width: 720px) {

    body {
        font-family: "Helvetica Neue", sans-serif;
    }
    
    h1, h2, h3, h4, a, b {
        text-align: center;

    }

    .wrapper {
        margin-top: 60px;
    }

   
    #menu-list {
        display: none;
    }



    .mobile-buttons {
        display: inline-block;
        /* float: right; */
        text-align: center;
        margin-right: .5em;
    }
    .mobile-buttons i{
        width: 100%;
        display: inline-block;
        text-align: center;
    }

    .search-mobile {
        position: relative;
        /* padding-bottom: 1em; */
        padding-top: .5em;

    }

    .lupa-mobile {
        position: absolute;
        right: 2em;
        top: 1em;
        z-index: 5;
    }

    .search-mobile input {
        margin-top: 0;
        margin-left: 5%;
        width: 85%;
        font-size: 1em;
        display: block;
        padding: .5em;
        border: 1px solid grey;
        padding-top: .6em;
        padding-bottom: .6em;

    }


    .content {
        /* width: 100%; */
        padding: 1em .5em;
    }

    .right-content {
        width: 100%;
    
    }
    
    .left-content {
        width: 100%;
    
    }

    .home-static-banner {
        width: 100%;

    }

    .home-static-banner img {
        width: 100%;
    }

    .news-slide-container img {
        width: 90%;

    }

    .slick-slider {
        overflow: hidden;

    }

    .product-container {
        width: 100%;
        position: relative;
        height: auto;  

    }

    .product-container .add-to-cart-button {
        position: relative;
        left: auto;
        margin-top: 1em;
        font-size: 1.2em;
        padding-top: 0.5em;
        padding-bottom: .5em;
   
    }
    

    .product-container img {
        position: relative;
        width: 50%;
        max-height: none;
        
    }

    .product-single {
        margin: 1em;
    }

    .product-single-images {
        width: 100%;
    }

    .product-signgle-cart {
        width: 100%;
    }

    .product-signgle-cart .shop-button {
        width: 100%;
    }

    .product-single-description {
        width: 100%;
        margin-left: 0;
    }

    .cart-submit {
        font-size: 1.1em;
        
    }


    .right-content p {
        margin-left: 12px;
        margin-right: 12px;

    }

    .right-content input {
        width: 94%;

    }



.top-products-sidebar {
    display: none;
}

.news-sidebar {
    display: none;
}

.categories-container {
    border: none;
    border-bottom: 1px solid gray;
    padding: 0;
    width: 100%;
    height: auto;
}

.categories-container ul {
    margin: 0;
    padding: 0;
}

.categories-container ul li {
    color: #999;
    display: block;
}

.categories-container h3{
    text-align: left;
    padding-left: 2em;
}

.categories-container a{
    /* font-size: 1.5em; */
    /* margin-top: 1em; */
    text-align: center;
    margin: 0.1em;
    /* margin-left: 3em; */
}

/* .categories-container a{ */

.product-container {
    font-size: 1em;
}

.product-container h3 {
    font-size: 1.7em;
    max-height: none;
    margin-top: 2em;
}

/* Product stickers */
.sticker-container-products{
    right: 3.3em;
    width: 40%;
}

.sticker-container-products p{
    top: .25em;
    left: 43%;
}

.sticker-container-product{
    right: 0em;
    margin-top: 1em;
}

.sticker-container-product p{
    top: .2em;
    left: 42%;
    font-size: 1.8em;
}

.product-container .add-to-cart-button {
    font-size: 1.2em;
    font-weight: bold;
}

.cart-product-head {
    margin-top: 0;
}

.cart-product {
    /* padding-top: .8em;
    padding-bottom: .8em; */
    padding-bottom: 1em;
}

.cart-product-head .title, .cart-product-head .price, .cart-product-head .quantity {
    display: none;
}

.cart-product div{
    padding-top: .5em;
    padding-bottom: .5em;
}

.cart-product .image {
    width: 20%;
}

.cart-product .title {
    width: 70%;
}

.cart-product .quantity {
    width: 40%;
}
.cart-product .quantity span {
    margin: auto;
}

.cart-product .price {
    width: 30%;
    font-size: 1.2em;
}

.cart-total {
    width: 90%;
    margin-left: 5%;
}

.cart-total h3{
    text-align: right;
}

    .cart-inputs {
        width: 100%;
        /* margin-right: 3%; */
        
    }

.cart-inputs input {
    display: inline-block;
    position: relative;
    width: 90%;
    font-size: 1.2em;
    padding-top: .5em;
    border: 1px solid #cccccc;
}

.cart-inputs h3 {
    text-align: left;
    margin-left: 1em;
}

.cart-inputs-billing {
    width: 90%;
    margin-left: 5%;
    padding-bottom: 2em;
    border-bottom: 1px solid rgb(179, 179, 179);
}
.cart-inputs-billing h4{
    text-align: left;
}

.cart-inputs-billing div {
    width: 80%;
}

.town-checkbox{
    margin-left: .5em;
}

input#dStreetNumber{
    width: 75%;
}

.cart-submit {
    padding: 1em;
}

.cart-submit button {
    font-size: 2em;
}




.product-left, .product-mid, .product-right {
    width: 90%;
    margin-left: 5%;
    text-align: center;
    padding-left: 0;
}

.comments {
    width: 90%;
    margin-left: 5%;
}


.registration {
    width: 100%;
    font-size: 1.2em;
}

.registration div{
    width: 100%;
}

.registration h2{
    width: auto;
}

.registration div:last-child {
    margin-left:0;
    padding-left:0;
}

.registration input {
    width: 90%;
    margin-left: 2.5%;
    border: 1px solid gray;
}

.registration p{
    width: 90%;
    margin-left: 5%;
}

.modal {
    right: 10%;
    width: 80%;
    z-index: 1;
}

.top-books {
    display: none;
}

.popup-content {
    width: 100% !important;
    height: 70%;
    margin-top: 1em !important;
}

.popup-slider-cont {
    width: 100%;
    height: 70%;
    left: 0;
    top: 15%;
    padding: 2%;
}

.popup-slider-cont .slick-slider img {
    max-width: 98%;
}

.popup-content .popup-prev, .popup-content .popup-next {
    font-size: 1.2em;
}

.secondary-images-cont {
    width: 90%;
    margin-left: 5%;
}
.secondary-images-cont-mobile {
    display: block;
}
.secondary-images-cont-desktop {
    display: none;
}
.secondary-image-cont{
    /* width: 23%; */
    margin-right: 1%
}

.popup-slider-cont .slick-slider {
    width: 96%;
}


.secondary-image-cont-mobile{
    display: block;
    margin-top: 1em;
    text-align: left;
}
.secondary-image-cont-desktop{
    display: none;
}

.product-video-cont {
    width: 100%;
}

.product-video-cont iframe{
    width: 100%;
    height: auto;
}

.news-slider-container {
    margin: 0;
}

.pdf-iframe {
    width: 90%;
    margin-left: 5%;
    height: 500px;
}




.home-product {
    width: 100%;
    margin: 0;
    margin-top: 2em;
    border-top: 1px solid #ccc;
    padding-top: 1em;
}

.home-product-image {
    margin: auto;
}

.shop-button {
    width: 60%;
    margin: auto;
    padding-top: .5em;
    padding-bottom: .5em;
}

.home-product p {
    height: unset;
    line-height: auto;
    text-align: center;
}

.home-product-info {
    margin: 1em auto;
    width: 270px;
}

.home-product-price {
    text-align: center;
}


.shop-button-order {
    display: block;
    margin: auto;
}

.data-left, .data-right {
    width: 100%;
    text-align: center;
}

.footer-bottom-left {
    width: 92%;
}

} /* media end */
.selector{
    position: relative;
    display: block;
    /* width: 90%; */
    /* padding: .4em;
    border: 1px solid gray; */
    border-radius: .3em;
    /* margin-bottom: 1em; */
}
.selector input{
    position: relative;
    display: block;
    /* width: 94%;
    padding: .4em */
}
.selector .selector-filter {
    color: black;
    font-size: .8em;
}
.selector .selector-filter input{
    display: inline-block;
    width: .5em;
    color: black;
}
.selector-options {
    font-size: 1em;
    position: absolute;
    left: 0;
    max-height: 18em;
    width: 105%;
    z-index: 2;
    background-color: white;
    border: gray;
    box-shadow: 5px 5px .5;
    overflow-y: scroll;
    border: 1px solid #ddd;
    border-top: none;
    box-sizing: border-box;
    box-shadow: 2px 11px 10px -2px rgba(0,0,0,0.2);
    border-bottom: 3px solid white;
    text-align: left;
}
.selector-options span{
    display: block;
    color: #666;
    cursor: pointer;
    padding-left: 1em;
    padding-top: .4em;
}
.selector-options span:hover{
    display: block;
    color: white;
    background: gray;
    cursor: pointer;
}
.selector-clear {
    position: absolute;
    top: .5em;
    right: .9em;
    cursor: pointer;
}
.selector-selected div {
    font-size: .6em;
    background: #666;
    padding: .3em .5em;
}
.selector-selected div button {
    margin-left: .5em;
    padding: .2em;
    padding-top: 0;
}

.selector-loader-container{
    position: relative;
}

.selector-loader{
    position: absolute;
    top: -27px;
    right: -10px;
    transition: right .2s;
}

@media screen and (max-width: 720px) {
    .selector input{
        display: unset;
    }

    .cart-street-box{
        margin-right: 3%;
    }

    .selector-loader{
        top: -29px;
        right: 18px;
    }
}
/* Admin */

.half {
    display: inline-block;
    width: 48%;
    vertical-align: top;
    padding: 1%;
}

.half .half-img {
    width: 100%!important;
}

.admin {
    font-family: sans-serif;
    font-size: 16px;
}

.admin-content{
    display: inline-block;
    width: 73%;
    padding: .5%;
    vertical-align: top;
    margin-left: 10%;
    padding-bottom: 5em;
}

.admin .admin-content h1{
    font-family: 'Merriweather';
    text-align: center;
    color: black;
}


.admin-content label {
    margin-bottom: .5em;
    font-family: 'Merriweather';
}

.admin-content input{
    padding: .5em;
    color: rgb(65, 65, 65);
    font-weight: normal;
    margin-top: .5em;
    margin-bottom: 1.5em;
}

.admin .ql-editor {
    height: 20em;
}


/* Admin header */
.admin-sidebar {
    height: 100%;
    width: 10%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #23282D;
    overflow-x: hidden;
    /* overflow-y: scroll; */
    text-align: left;
}

.admin-logout {
    position: absolute;
    bottom: 1px;
    cursor: pointer;
    border: none;
}

.admin-sidebar-content {
    padding: 5px;
}

.admin-controls {
    height: 100%;
    width: 15%;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color:whitesmoke;
    overflow-x: hidden;
    /* overflow-y: scroll; */
    text-align: left;
    padding: .5%;
    border-left: 1px solid #ddd;
}

.admin .button {
    cursor: pointer;
    border-radius: .2em;
    background: rgb(58, 138, 192);
    color: white;
    display: inline-block;
    padding: .4em 1em;
    margin: 10px 0;
}

.admin-controls .button {
    display: block;
    text-align: center;
}
.admin .button:hover {
    opacity: .8;
}

.admin .html-location-button {
    cursor: pointer;
    border-radius: .2em;
    background: rgb(58, 138, 192);
    color: white;
    display: inline-block;
    padding: .4em 1em;
    margin-right: .5em;
    margin-bottom: .5em;
}

.admin .html-location-button:hover {
    opacity: .8;
}

.admin .html-location-button.active {
    background: rgb(41, 98, 136);
}

.admin label {
    font-size: .9em;
    color: #666;
}

.admin-controls hr {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.admin-product-categories {
    border: 1px solid gray;
    background: white;
    border-radius: .3em;
    padding: .5em;
}
.button-category-add {
    display: inline-block;
    /* text-align: center; */
    margin: .2em;
    padding: .1em 0.4em .1em .2em;
    background: #a5a5a5;
    color: white;
    border-radius: .2em;
    cursor: pointer;
}

.admin-controls label {
    display: inline-block;
    vertical-align: top;
    top: .15em;
    position: relative;
    margin-left: .2em;
}
.admin-controls input[type=checkbox] {
    display: inline-block;
}

.admin h3 {
    font-family: 'Merriweather';
    font-weight: normal;
    margin-bottom: .5em;
    color: #333;
}

.admin input[type=text] {
    margin-top: 0;
}

.admin-login label {
    display: block;
}

.admin-login {
    text-align: center;
    padding-top: 2em;
}
.admin-login input{
    margin-top: .5em;
    margin-bottom: 1em;
    width: 382px;
    /* border-color: rgb(189, 189, 189); */
    border: 1px solid #ccc;
    border-radius: .2em;
    /* display: none; */
}
.admin-login-button-container {
    text-align: right;
}

.admin-login .admin-login-inputs {
    width: 400px;
    margin: auto;
    display: block;
    position: relative;
    padding: 2em;
    background: whitesmoke;
    text-align: left;
    border-radius: .2em;
}

.admin-login .admin-login-inputs h1 {
    text-align: center;
}

.admin-product-categories {
    list-style-type: none;
    padding: 0;
}


.admin-sidebar ul {
    list-style-type: none;
    padding: 0;
   
}

.admin-sidebar ul li {
    color: #ccc;
    font-weight: 200;
    margin-bottom: .5em;
    border-bottom: 1px solid #484848;
    padding-bottom: .5em;
}
.admin-sidebar ul li:hover {
     color: #fff;
     font-weight: normal;
}

.admin .admin-item-active {
    font-weight: normal;
    color: #fff;
}

.admin-sidebar a {
    text-decoration: none;
    color: inherit;
}

.admin table {
    width: 100%;
    border-collapse: collapse;
}

.admin table tr:nth-child(even) {
    background: whitesmoke;
}

.admin table td {
    padding: .5em;
}

.admin table th:last-child {
    text-align: right;
}

.admin table td:last-child {
    text-align: right;
}
  
/* .admin-sidebar a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;

    color: white;
    display: block;
}
  
.admin-sidebar a:hover {
    color: #f1f1f1;
} */
  
/* Admin media */

.admin-media-img {
    max-width: 75px;
    max-height: 75px;
    height: auto;
    display: inline-block;
    margin: .5em;
}

/* Admin variations */
.variations-content {
    position: relative;
    width: 100%;
}

.variations-left {
    width: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
}

.variations-right {
    width: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
}

/* Admin pages */
.admin-pages-content {
    position: relative;
    width: 100%;
}

/* Admin categories */
.categories-content {
    position: relative;
    width: 100%;
}

.categories-left {
    width: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
}

.categories-right {
    width: 40%;
    display: inline-block;
    position: absolute;
    top: 0;
    margin-left: 10%;
}

/* Admin product */
.product-thumbnail {
    width: 100%;
}

.product-gallery-thumbnail {
    display: inline-block;
    width: 98%;
    vertical-align: top;
    margin: 1%;
    margin-right: 0;
}

.product-media-container {
    width: 70%;
    overflow-y: scroll;
    height: 90%;
    background-color: whitesmoke;
    z-index: 10;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1em;
    border-radius: .5em;
    border: 1px solid gray;
    box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.72);
}

.product-media-container h2{
    margin-top: 0;
}

.youtube-upload-container {
    width: 70%;
    height: 25%;
    background-color: whitesmoke;
    z-index: 10;
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1em;
    border-radius: .5em;
    border: 1px solid gray;
    box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.72);
}

.youtube-upload-container h2{
    margin-top: 0;
}

.selected{
    border: 2px solid black;
}

.not-selected{
    border: 2px solid transparent;
}

.edit-products-tab {
    height: 100%;
    width: 260px;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: lightgray;
    overflow-x: hidden;
    padding-top: 20px;
}

.admin .pagination {
    text-align: center;
    margin: 1em;
}

.admin-product-active label, .admin-product-active input{
    display: inline-block;
}

.admin-product-featured label, .admin-product-featured input{
    display: inline-block;
}

.admin-category-active label, .admin-category-active input{
    display: inline-block;
}

.admin-page-active label, .admin-page-active input{
    display: inline-block;
}

.admin-product-gallery {
    position: relative;
}

.admin-product-gallery-image-container {
    position: relative;
    width: 32%;
    display: inline-block;
}

.product-gallery-delete {
    position: absolute;
    top: 2px;
    left: 33%;
    width: 30%;
    opacity: .5;
    cursor: pointer;
}

.product-gallery-move-left {
    position: absolute;
    top: 2px;
    left: 6%;
    width: 30%;
    opacity: .5;
    cursor: pointer;
}

.product-gallery-move-right {
    position: absolute;
    top: 2px;
    left: 63%;
    width: 30%;
    opacity: .5;
    cursor: pointer;
}

.product-gallery-delete:hover, .product-gallery-move-left:hover, .product-gallery-move-right:hover {
    opacity: 1;
}

.admin-media-gallery-image-container{
    position: relative;
    display: inline-block;
}

.media-gallery-delete {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 20px;
    opacity: .5;
    cursor: pointer;
}

.media-gallery-delete:hover {
    opacity: 1;
}

.admin-content input.admin-product-input-name {
    font-size: 2em;
    border: none !important;
    text-align: center;
    font-family: 'Merriweather';
    color: black;
    font-weight: bold;
}

/* Admin banners */

.banner-container{
    width: 100%;
    position: relative;
}

.banner-image {
    position: relative;
    /* vertical-align: top; */
    text-align: center;
    width: 60%;
    height: 200px;
    border: 1px solid rgb(202, 202, 202);
    /* background: rgb(224, 224, 224); */
    display: block;
    margin: 2px;
    border-radius: 3px;
    display: inline-block;
}

.banner-image-hide{
    opacity: .5;
}

.banner-image img {
    max-width: 100%;
    max-height: 100%;
}

.banner-controls input, .banner-controls label {
    display: inline-block;
}

.banner-actions{
    width: 25%;
    display: inline-block;
    position: absolute;
}

.banner-actions.button{
    width: 100%;
}

.admin-banner-link {
    position: relative;
    margin: 20px 0;
}

.admin-banner-link label {
    width: 7%;
    display: inline-block;
}

.admin-banner-link input {
    width: 77%;
    display: inline-block;
    margin-right: 1%;
}

.admin-banner-link .button {
    width: 8%;
}

/* Product tooltip */
.tooltip {
    position: relative;
    display: inline-block;
    margin-left: .3em;
}
  
.tooltip .tooltiptext {
visibility: hidden;
width: 120px;
background-color: #a5a5a5;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px 0;
position: absolute;
z-index: 1;
bottom: 150%;
left: 50%;
margin-left: -60px;
}
  
.tooltip .tooltiptext::after {
content: "";
position: absolute;
top: 100%;
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: #a5a5a5 transparent transparent transparent;
}
  
.tooltip:hover .tooltiptext {
visibility: visible;
}

/* Order page */
.admin-order {
    position: relative;
    display: flex;
    margin: 3em 0;
}

.admin-order-billing, .admin-order-shipping {
    width: 50%;
    display: inline-block;
}

.media-module img{
    width: 64px;
    display: inline-block;
    margin: .3em;
    cursor: pointer;
    vertical-align: middle;
}
.media-module img:hover{
    opacity: .8;
}
.media-module {
    position: fixed;
    top: 10%;
    left: 10%;
    height: 80%;
    width: 80%;
    z-index: 10;
    background: white;
    padding: .5em;
    border-radius: .5em;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.shadow {
    box-shadow:
    5px 10px #000000,
}
.media-module .head {
    display: block;
    height: 30%;
}
.media-module .body {
    display: block;
    height: 58%;
    overflow-y: scroll;
}
.media-module .media-footer {
    display: block;
    height: 8%;
}

.media-preview-image {
    position: fixed;
    top: 10%;
    left: 10%;
    height: 80%;
    width: 80%;
    z-index: 10;
    background: white;
    padding: .5em;
    border-radius: .5em;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.media-preview-image .media-preview-footer {
    display: block;
    height: 10%;
}

.media-preview-image .media-preview-head {
    display: block;
    height: 90%;
    position: relative;
}

.media-preview-image img{
    max-width: 100%!important;
    height: auto;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-select-vd {
    position: relative;
}

.react-select-vd input:not([type=checkbox]) {
    display: block;
    padding: .6em .5em;
    border: 1px solid #ccc;
    border-radius: .2em;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
    margin: .5em 0;
}

.react-select-vd .react-select-vd input {
    margin-bottom: 0;
}

.react-select-vd .react-select-vd-results {
    max-height: 10em;
    overflow-y: scroll;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 0 0 .5em .5em;
    background: white;
    z-index: 100;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    border-top: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.react-select-vd .react-select-vd-result {
    padding: .5em;
    
}

.react-select-vd .selected, .react-select-vd-result:hover {
    color: white;
    background: gray;
}

.react-select-vd-input-container {
    position: relative;
}

.arrow {
    height: 1.5em;
    position: absolute;
    top: 0.4em;
    right: 0;
}

.closed {
    transform: rotate(90deg);
}

.react-select-vd-selected {
    position: absolute;
    top: 0;
    padding: 0.6em;
    margin: 0;
}


