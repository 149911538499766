.react-select-vd {
    position: relative;
}

.react-select-vd input:not([type=checkbox]) {
    display: block;
    padding: .6em .5em;
    border: 1px solid #ccc;
    border-radius: .2em;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
    margin: .5em 0;
}

.react-select-vd .react-select-vd input {
    margin-bottom: 0;
}

.react-select-vd .react-select-vd-results {
    max-height: 10em;
    overflow-y: scroll;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 0 0 .5em .5em;
    background: white;
    z-index: 100;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    border-top: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.react-select-vd .react-select-vd-result {
    padding: .5em;
    
}

.react-select-vd .selected, .react-select-vd-result:hover {
    color: white;
    background: gray;
}

.react-select-vd-input-container {
    position: relative;
}

.arrow {
    height: 1.5em;
    position: absolute;
    top: 0.4em;
    right: 0;
}

.closed {
    transform: rotate(90deg);
}

.react-select-vd-selected {
    position: absolute;
    top: 0;
    padding: 0.6em;
    margin: 0;
}

